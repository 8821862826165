import { Seminars } from './Widgets';

export const CustomerInsights = () => {
    return (
        <div className="flex flex-auto border-2 p-4">
            <Seminars />
        </div>
    );
};

export default CustomerInsights;
